import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TableCell,
  TextField,
  Collapse,
  Tooltip,
  Typography,
  TableContainer,
  Checkbox,
  ListItemText,
  Chip,
  Table,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LinkIcon from '@mui/icons-material/Link'
import PersonIcon from '@mui/icons-material/Person'
import CopyrightFooter from 'components/CopyrightFooter'

import {
  Search,
  ManageSearch,
  CloudUpload,
  Clear,
  OpenInNew,
} from '@mui/icons-material'
import api from 'lib/api'
import styled from '@emotion/styled'
import moment from 'moment'
import EnhancedTable from 'components/DataTable'
import {
  setSamRows,
  setSbirRows,
  setClosedSbirRows,
  clearRfpData,
  setSelectedFile,
} from 'reducers/rfpSlice'
import { set } from 'lodash'
import { useSelector } from 'react-redux'

const CustomTableContainer = styled(TableContainer)({
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
})
const HeaderTableCell = styled(TableCell)({
  backgroundColor: '#E0E0E0',
  fontWeight: 'bold',
  verticalAlign: 'top',
})

const MultiLineTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  maxWidth: '500px',
  WebkitLineClamp: 3,
  whiteSpace: 'normal',
})

const ScrollableTableCell = styled(TableCell)({
  maxWidth: '200px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

const renderSimilarityBar = (similarity) => {
  const bars = []
  let color = 'red'
  let barCount = 1

  if (similarity > 45) {
    color = 'green'
    barCount = 3
  } else if (similarity >= 20) {
    color = 'yellow'
    barCount = 2
  }

  for (let i = 0; i < 3; i++) {
    bars.push(
      <Box
        key={i}
        sx={{
          width: '16px',
          height: '8px',
          backgroundColor: i < barCount ? color : '#e0e0e0',
          marginRight: 0.5,
          display: 'inline-block',
          borderRadius: '5px',
        }}
      />
    )
  }

  return (
    <Tooltip
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            sx={{ marginRight: 1 }}>{`${similarity}%`}</Typography>
          <Box sx={{ display: 'flex' }}>{bars}</Box>
        </Box>
      }>
      <Box sx={{ cursor: 'pointer' }}>{bars}</Box>
    </Tooltip>
  )
}

const CompanyDataSelector = (props) => {
  const [loading, setLoading] = useState(true)
  const [selectFileOpen, setSelectFileOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const handleOpenSelectFile = () => setSelectFileOpen(true)
  const handleCloseSelectFile = () => setSelectFileOpen(false)
  const tenantId = useSelector((state) => state.user.tenantId)

  const fetchUploadedFiles = async () => {
    try {
      const response = await api.tenants.rfps.get({ tenantId })
      if (response && response.data && response.data.rfps) {
        setUploadedFiles(response.data.rfps)
      } else {
        console.error('Unexpected API response format:', response)
      }
    } catch (e) {
      console.error(
        'Error fetching files:',
        e.message,
        e.response ? e.response.data : 'No response data'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchUploadedFiles()
  }, [props.companyData])

  return (
    <>
      <FormControl
        required
        fullWidth
        disabled={props.companyData && props.companyData.length > 0}>
        <InputLabel id="file-select-label">Select Company Data</InputLabel>
        <Select
          labelId="file-select-label"
          id="file-select"
          value={props.selectedFile}
          label="Select Company Data"
          onChange={props.handleSelectedFile}
          open={selectFileOpen}
          onOpen={handleOpenSelectFile}
          onClose={handleCloseSelectFile}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: 2,
                cursor: 'progress',
              }}>
              <CircularProgress />
            </Box>
          ) : (
            uploadedFiles.map((file, index) => (
              <MenuItem
                value={file.id}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}>
                  <Typography variant="body1">{file.name}</Typography>
                  <Typography fontSize={8}>{file.filename}</Typography>
                </Box>
                <Typography variant="caption">{file.description}</Typography>
              </MenuItem>
            ))
          )}
          <CompanyDataUpload
            key="NEW"
            value="NEW"
            title="Upload a new company document"
            onChange={(e) => props.setCompanyData(e.target.files)}
            fileValue={props.companyData}
            setSelectedFile={props.setSelectedFile}
            handleClose={handleCloseSelectFile}
            setIsUploading={props.setIsUploading}
            setIsUploadFormOpen={props.setIsUploadFormOpen}>
            {props.companyData &&
              props.companyData.length > 0 &&
              props.companyData[0].name}
          </CompanyDataUpload>
        </Select>
      </FormControl>
      {props.companyData && props.companyData.length > 0 && (
        <Tooltip
          title="Remove file"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -100],
                  },
                },
              ],
            },
          }}>
          <IconButton
            aria-label="clear-file"
            sx={{ width: 56 }}
            onClick={() => {
              props.setCompanyData([])
              props.setSelectedFile('')
              props.clear()
            }}>
            <Clear />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

const CompanyDataUpload = (props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 100,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  useEffect(() => {
    if (props.fileValue && props.fileValue.length > 0) {
      props.setSelectedFile('NEW')
      props.handleClose()
      props.setIsUploadFormOpen(true)
    }
  }, [
    props.fileValue,
    props.setSelectedFile,
    props.handleClose,
    props.setIsUploadFormOpen,
  ])

  return (
    <MenuItem
      value={props.value}
      component="label"
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        gap: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'background.paper',
        },
        '@media (hover: none)': {
          backgroundColor: 'primary.main',
          color: 'background.paper',
        },
      }}>
      <VisuallyHiddenInput
        type="file"
        onChange={props.onChange}
        multiple={props.multiple}
        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.csv,.xml,.json"
      />
      <Typography>{props.title}</Typography>
      <Box tabIndex={-1} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <CloudUpload />
      </Box>
    </MenuItem>
  )
}

const RFPSearchAndManagementPage = (props) => {
  const tenantId = useSelector((state) => state.user.tenantId)
  const [SAMView, setSAMView] = useState(false)
  const [sbirheadCells, setSbirHeadCells] = useState([
    {
      id: 'similarity',
      numeric: false,
      disablePadding: false,
      label: 'Similarity',
    },
    {
      id: 'solicitationTitle',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation title',
    },
    {
      id: 'solicitationNumber',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation number',
    },
    { id: 'program', numeric: false, disablePadding: false, label: 'Program' },
    { id: 'Phase', numeric: false, disablePadding: false, label: 'Phase' },
    { id: 'agency', numeric: false, disablePadding: false, label: 'Agency' },
    { id: 'branch', numeric: false, disablePadding: false, label: 'Branch' },
    {
      id: 'solicitation_year',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation year',
    },
    {
      id: 'release_date',
      numeric: false,
      disablePadding: false,
      label: 'Release Date',
    },
    {
      id: 'open_date',
      numeric: false,
      disablePadding: false,
      label: 'Open Date',
    },
    {
      id: 'close_date',
      numeric: true,
      disablePadding: false,
      label: 'Close Date',
    },
    {
      id: 'currentStatus',
      numeric: false,
      disablePadding: false,
      label: 'Current status',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'solicitationAgencyUrl',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation link',
    },
    {
      id: 'solicitation_agency_link',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation agency link',
    },
  ])
  const [samheadCells, setSamHeadCells] = useState([
    {
      id: 'similarity',
      numeric: false,
      disablePadding: false,
      label: 'Similarity',
    },
    {
      id: 'solicitationTitle',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation Title',
    },
    {
      id: 'solicitationNumber',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation number',
    },
    {
      id: 'fullParentPathName',
      numeric: false,
      disablePadding: false,
      label: 'Agency',
    },
    {
      id: 'fullParentPathNameCode',
      numeric: false,
      disablePadding: false,
      label: 'Agency code',
    },
    {
      id: 'posted_date',
      numeric: false,
      disablePadding: false,
      label: 'Posted Date',
    },
    {
      id: 'archived_date',
      numeric: false,
      disablePadding: false,
      label: 'Archived Date',
    },
    {
      id: 'responseDeadline',
      numeric: false,
      disablePadding: false,
      label: 'Response Deadline',
    },
    {
      id: 'naicsCode',
      numeric: false,
      disablePadding: false,
      label: 'Naics Code',
    },
    {
      id: 'classification_code',
      numeric: false,
      disablePadding: false,
      label: 'Classification code',
    },
    { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
    {
      id: 'awardDate',
      numeric: false,
      disablePadding: false,
      label: 'Award Date',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    { id: 'uiLink', numeric: false, disablePadding: false, label: 'Ui link' },
    {
      id: 'resourcesLink',
      numeric: false,
      disablePadding: false,
      label: 'Resources link',
    },
    {
      id: 'pointOfContact',
      numeric: false,
      disablePadding: false,
      label: 'Point Of contact',
    },
  ])
  const [openDescriptionSbir, setOpenDescriptionSbir] = useState(false)
  const [loadingSBIR, setLoadingSBIR] = useState(false)

  const [loadingSBIRWithDomain, setLoadingSBIRWithDomain] = useState(false)
  const [loadingSAM, setLoadingSAM] = useState(false)
  const {
    samRows,
    sbirRows,
    closedSbirRows,
    setSamRows,
    setSbirRows,
    setClosedSbirRows,
    clearRfpData,
  } = props

  // const [selectedFile, setSelectedFile] = useState('')
  const [companyData, setCompanyData] = useState([])
  const [fileName, setFileName] = useState('')
  const [fileDescription, setFileDescription] = useState('')
  const [open, setOpen] = useState(false)
  const [synopsisOpen, setSynopsisOpen] = useState(false)
  const [keywords, setKeywords] = useState([])
  const [searchName, setSearchName] = useState('')
  const [filters, setFilters] = useState([])
  const [typeOfSetAside, setTypeOfSetAside] = useState('')
  const [solicitationType, setSolicitationType] = useState([]);
  const [isSolicitationTypeOpen, setIsSolicitationTypeOpen] = useState(false)
  const solicitationTypesOptions = ['SBIR', 'STTR', 'BAA', 'All others']
  const [selectedFilter, setSelectedFilter] = useState('')
  const [domains, setDomains] = useState([])
  const [isNaicsOpen, setIsNaicsOpen] = useState(false)
  const [isAgencyOpen, setIsAgencyOpen] = useState(false)
  const [isDateOpen, setIsDateOpen] = useState(false)
  const [isTypeOfSetAsideOpen, setIsTypeOfSetAsideOpen] = useState(false)
  const [isDomainSpecificOpen, setIsDomainSpecificOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(false)
  const [naicsCode, setNaicsCode] = useState('')
  const [agency, setAgency] = useState('')
  const rate = true
  const [withDomains, setWithDomains] = useState(false)
  const [dateFrom, setDateFrom] = useState(
    moment().startOf('year').format('YYYY-MM-DD')
  )

  const [dateTo, setDateTo] = useState(
    moment().endOf('year').format('YYYY-MM-DD')
  )

  const [samTotalCount, setSamTotalCount] = useState(0)
  const [sbirTotalCount, setSbirTotalCount] = useState(0)
  const [closedSbirTotalCount, setClosedSbirTotalCount] = useState(0)

  const [samPage, setSamPage] = useState(0)
  const [samRowsPerPage, setSamRowsPerPage] = useState(10)
  const [isLastPage, setIsLastPage] = useState(false)

  const [sbirPage, setSbirPage] = useState(0)
  const [sbirRowsPerPage, setSbirRowsPerPage] = useState(10)

  const [closedSbirPage, setClosedSbirPage] = useState(0)
  const [closedSbirRowsPerPage, setClosedSbirRowsPerPage] = useState(10)

  const getCurrentPosts = (rows, page, rowsPerPage) => {
    const firstIndex = page * rowsPerPage
    const lastIndex = firstIndex + rowsPerPage
    return rows.slice(firstIndex, lastIndex)
  }

  const [selectedSolicitation, setSelectedSolicitation] = useState({
    title: '',
    description: '',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const filterSolicitations = (data) => {
    if (!data || data.length === 0) return data
    if (!searchQuery) return data
    return data.filter((row) => {
      const query = searchQuery.toLowerCase()

      const title = row.title || row.solicitationTitle || ''
      const solicitationNumber = row.solicitationNumber || ''
      const agency = row.agency || row.fullParentPathName || ''
      const description = row.description || ''
      const program = row.program || ''
      const phase = row.phase || ''
      const currentStatus = row.currentStatus || row.active || ''

      return (
        title.toLowerCase().includes(query) ||
        solicitationNumber.toLowerCase().includes(query) ||
        agency.toLowerCase().includes(query) ||
        description.toLowerCase().includes(query) ||
        program.toLowerCase().includes(query) ||
        phase.toLowerCase().includes(query) ||
        currentStatus.toLowerCase().includes(query)
      )
    })
  }

  const filteredSamRows = useMemo(() => {
    return filterSolicitations(samRows)
  }, [samRows, searchQuery])

  const filteredSbirRows = useMemo(() => {
    return filterSolicitations(sbirRows)
  }, [sbirRows, searchQuery])

  const filteredClosedSbirRows = useMemo(() => {
    return filterSolicitations(closedSbirRows)
  }, [closedSbirRows, searchQuery])

  const samCurrentPageData = useMemo(() => {
    return getCurrentPosts(filteredSamRows, samPage, samRowsPerPage)
  }, [filteredSamRows, samPage, samRowsPerPage])

  const sbirCurrentPageData = useMemo(() => {
    return getCurrentPosts(filteredSbirRows, sbirPage, sbirRowsPerPage)
  }, [filteredSbirRows, sbirPage, sbirRowsPerPage])

  const closedSbirCurrentPageData = useMemo(() => {
    return getCurrentPosts(
      filteredClosedSbirRows,
      closedSbirPage,
      closedSbirRowsPerPage
    )
  }, [filteredClosedSbirRows, closedSbirPage, closedSbirRowsPerPage])

  const [pointOfContact, setPointOfContact] = useState(null)
  const [isPocModalOpen, setIsPocModalOpen] = useState(false)
  const handleDelete = (value) => {
  setSolicitationType((prev) => prev.filter((item) => item !== value));
  };
  


  const handleOpenPocModal = (event, contact) => {
    event.preventDefault()
    event.stopPropagation()
    setPointOfContact(contact)
    setIsPocModalOpen(true)
  }

  const handleClosePocModal = () => {
    setIsPocModalOpen(false)
    setPointOfContact(null)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setIsUploadFormOpen(false)
    setIsUploading(false)

    setCompanyData([])
    setFileName('')
    setFileDescription('')
    setDomains([])

    // Only reset selectedFile if cancelling an upload
    if (isUploadFormOpen || isUploading) {
      props.setSelectedFile('')
      setKeywords([])
    }
  }
  const handleOpenSynopsis = () => setSynopsisOpen(true)
  const handleCloseSynopsis = () => setSynopsisOpen(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadFormOpen, setIsUploadFormOpen] = useState(false)
  const handleSelectedFile = async (event) => {
    const selectedValue = event.target.value
    props.setSelectedFile(selectedValue);

    if (selectedValue === 'NEW') {
      setIsUploadFormOpen(true)
    } else {
      setIsUploading(false)
      try {
        const response = await api.tenants.rfps.get({ tenantId })

        const doms = response.data.rfps.find((element) => {
          return element.id === selectedValue
        })
        setDomains(doms.domains)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleRowClick = (row) => {
    setSelectedSolicitation({
      title: row.solicitationTitle,
      description: row.description,
    })
    handleOpenSynopsis()
  }

  const handlePastClosedRowClick = (row) => {
    setSelectedSolicitation({
      title: row.solicitationTitle,
      description: row.description,
    })
    handleOpenSynopsis()
  }

  const handleSamRowClick = (row) => {
    const sbirMatch = sbirRows.find(
      (sbirRow) => sbirRow.solicitationTitle === row.title
    )
    const sbirClosedMatch = closedSbirRows.find(
      (closedRow) => closedRow.solicitationTitle === row.title
    )

    const description = sbirMatch
      ? sbirMatch.description
      : sbirClosedMatch
      ? sbirClosedMatch.description
      : 'No detailed description available for this SAM solicitation.'

    setSelectedSolicitation({
      title: row.title,
      description: description,
    })

    handleOpenSynopsis()
  }

  

  const rowCells = (row) => (
    <>
      <TableCell align="left">{renderSimilarityBar(row.similarity)}</TableCell>
      <ScrollableTableCell align="left">
        <Tooltip title={row.solicitationTitle} placement="top">
          <MultiLineTypography variant="body2">
            {row.solicitationTitle}
          </MultiLineTypography>
        </Tooltip>
      </ScrollableTableCell>
      <TableCell align="left">{row.solicitationNumber}</TableCell>
      <TableCell align="left">{row.program}</TableCell>
      <TableCell align="left">{row.phase}</TableCell>
      <ScrollableTableCell align="left">
        <Tooltip title={row.agency} placement="top">
          <MultiLineTypography variant="body2">
            {row.agency}
          </MultiLineTypography>
        </Tooltip>
      </ScrollableTableCell>
      <TableCell align="left">{row.branch}</TableCell>
      <TableCell align="left">{row.solicitationYear}</TableCell>
      <TableCell align="left">{row.release_date}</TableCell>
      <TableCell align="left">{row.open_date}</TableCell>
      <TableCell align="left">{row.close_date}</TableCell>
      <TableCell align="left">{row.currentStatus}</TableCell>
      <ScrollableTableCell align="left">
        <Tooltip title={row.description} placement="top">
          <MultiLineTypography variant="body2">
            {row.description}
          </MultiLineTypography>
        </Tooltip>
      </ScrollableTableCell>
      <ScrollableTableCell align="left">
        <Tooltip title="View Solicitation Link">
          <IconButton
            href={row.sbirSolicitationLink}
            target="_blank"
            rel="noreferrer"
            sx={{ color: 'blue' }}>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      </ScrollableTableCell>
      <ScrollableTableCell align="left">
        <Tooltip title="View Agency Link">
          <IconButton
            href={row.solicitationAgencyUrl}
            target="_blank"
            rel="noreferrer"
            sx={{ color: 'blue' }}>
            <OpenInNew />
          </IconButton>
        </Tooltip>
      </ScrollableTableCell>
    </>
  )

  const rowSamCells = (row) => (
    console.log('Rendering SAM Row:', row),
    (
      <>
        <TableCell align="left">
          {renderSimilarityBar(row.similarity)}
        </TableCell>
        <ScrollableTableCell align="left">
          <Tooltip title={row.title} placement="top">
            <MultiLineTypography variant="body2">
              {row.title}
            </MultiLineTypography>
          </Tooltip>
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          {row.solicitationNumber}
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          <Tooltip title={row.agency} placement="top">
            <MultiLineTypography variant="body2">
              {row.agency}
            </MultiLineTypography>
          </Tooltip>
        </ScrollableTableCell>
        <TableCell align="left">{row.agencyCode}</TableCell>
        <TableCell align="left">{row.postedDate}</TableCell>
        <TableCell align="left">{row.archiveDate}</TableCell>
        <TableCell align="left">{row.responseDeadline}</TableCell>
        <TableCell align="left">{row.naicsCode}</TableCell>
        <TableCell align="left">{row.classificationCode}</TableCell>
        <TableCell align="left">{row.active}</TableCell>
        <TableCell align="left">{row.awardDate}</TableCell>
        <ScrollableTableCell align="left">
          <Tooltip title={row.description} placement="top">
            <MultiLineTypography variant="body2">
              {row.description}
            </MultiLineTypography>
          </Tooltip>
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          <Tooltip title="View UI Link">
            <IconButton
              href={row.uiLink}
              target="_blank"
              rel="noreferrer"
              sx={{ color: 'blue' }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          <Tooltip title="View Resource Links">
            <IconButton
              href={row.resourceLinks}
              target="_blank"
              rel="noreferrer"
              sx={{ color: 'blue' }}>
              <OpenInNew />
            </IconButton>
          </Tooltip>
        </ScrollableTableCell>
        <ScrollableTableCell align="left">
          <IconButton
            color="primary"
            onClick={(event) => handleOpenPocModal(event, row.pointOfContact)}
            aria-label="view details">
            <PersonIcon />
          </IconButton>

          {/* <IconButton onClick={(event) => handleOpenPocModal(row.pointOfContact)}>
          <PersonIcon sx={{ color: 'blue' }} />
        </IconButton> */}
          {/* <IconButton onClick={() => handleOpenPocModal(row.pointOfContact)}>
          <PersonIcon sx={{ color: 'gray' }} />
        </IconButton> */}
        </ScrollableTableCell>
      </>
    )
  )

  const createSamData = (row) => {
    let awardDate = '-'

    if (row.award) {
      try {
        const parsedAward = JSON.parse(row.award)
        if (parsedAward.date) {
          awardDate = moment(parsedAward.date).format('MM/DD/YYYY')
        }
      } catch (error) {
        console.error('Error parsing award data:', error)
      }
    }
    const data = {
      similarity: (row.similarity * 100).toFixed(2),
      title: row.title,
      solicitationNumber: row.solicitation_number,
      agency: row.full_parent_path_name,
      agencyCode: row.full_parent_path_code,
      naicsCode: row.naics_code,
      classificationCode: row.classification_code,
      active: row.active,
      description: row.description,
      uiLink: row.ui_link,
      resourceLinks: row.resource_links?.replace(/^[\[\]"']+|[\[\]"']+$/g, ''),
      pointOfContact: {
        fax: JSON.parse(row.point_of_contact).fax,
        type: JSON.parse(row.point_of_contact).type,
        email: JSON.parse(row.point_of_contact).email,
        phone: JSON.parse(row.point_of_contact).phone,
        fullName: JSON.parse(row.point_of_contact).fullName,
      },
      awardDate,
      postedDate: row.posted_date
        ? moment(row.posted_date).format('MM/DD/YYYY')
        : 'N/A',
      archiveDate: row.archive_date
        ? moment(row.archive_date).format('MM/DD/YYYY')
        : 'N/A',
      responseDeadline: row.response_deadline
        ? moment(row.response_deadline).format('MM/DD/YYYY')
        : 'N/A',
    }
    return data
  }

  const createSbirData = (row) => {
    const data = {
      similarity: (row.similarity * 100).toFixed(2),
      solicitationTitle: row.solicitation_title,
      solicitationNumber: row.solicitation_number,
      agency: row.agency,
      branch: row.branch,
      solicitationYear: row.solicitation_year,
      phase: row.phase,
      program: row.program,
      currentStatus: row.current_status,
      description: row.description,
      sbirSolicitationLink: row.sbir_solicitation_link,
      solicitationAgencyUrl: row.solicitation_agency_url,
      release_date: row.release_date
        ? moment(row.release_date).format('MM/DD/YYYY')
        : 'N/A',
      open_date: row.open_date
        ? moment(row.open_date).format('MM/DD/YYYY')
        : 'N/A',
      close_date: row.close_date
        ? moment(row.close_date).format('MM/DD/YYYY')
        : 'N/A',
      rating: row.rating,
    }
    return data
  }

  const handleUpload = async () => {
    if (companyData.length === 0) {
      alert('Please select a file to upload.')
      return
    }
    if (fileName.trim() === '' || fileDescription.trim() === '') {
      alert('Please provide both file name and description.')
      return
    }

    setIsUploading(true)
    try {
      const formData = new FormData()
      formData.append('document', companyData[0])
      formData.append('name', fileName)
      formData.append('description', fileDescription)

      const response = await api.rfp.files({ tenantId, formData })

      setDomains(companyData[0].domains)
      const uploadedFileId = response.data.id
    props.setSelectedFile(uploadedFileId)
      setCompanyData([])
      setFileName('')
      setFileDescription('')

      if (response.data.domains) {
        setDomains(response.data.domains)
      }

      alert('Document uploaded successfully!')
      setIsUploading(false)
      setIsUploadFormOpen(false)
      return response.data
    } catch (e) {
      console.error(e)
    } finally {
      setIsUploading(false)
    }
  }

  const handleSearchSbirSam = async (page, rowsPerPage) => {
    const required = true

    const solicitationTypeFilter = solicitationType.join(',');

    setLoadingSBIR(true);
    let newRfpId = null;
    if (props.selectedFile === 'NEW') {
      newRfpId = await handleUpload();
    }

    try {
      const response = await api.rfp.sam({
        rate,
        dateFrom,
        dateTo,
        keywords,
        tenantId,
        rfpId:  newRfpId || props.selectedFile,
        solicitationType: solicitationTypeFilter,
        agency,
        naicsCode,
        typeOfSetAside,
        required,
        perPage: rowsPerPage,
        page: page + 1,
        withDomains,
      })

      const samData = response.data.results.map((row) => createSamData(row))
      setSamRows(samData)

      // Determining if this is the last page
      if (samData.length < rowsPerPage) {
        setIsLastPage(true)
      } else {
        setIsLastPage(false)
      }
      // const sbirResponse = await api.rfp.sbir({
      //   dateFrom,
      //   dateTo,
      //   keywords,
      //   tenantId,
      //   closed: 0,
      //   required,
      //   rfpId: selectedFile,
      //   perPage: rowsPerPage,
      //   page: page + 1,
      //   withDomains,
      // })
      // const sbirData = sbirResponse.data.results.map((row) =>
      //   createSbirData(row)
      // )
      // setSbirRows(sbirData)
      // setSbirTotalCount(samData.length)

      // const sbirClosedResponse = await api.rfp.sbir({
      //   dateFrom: dateFrom,
      //   dateTo: dateTo,
      //   keywords,
      //   rate,
      //   tenantId,
      //   closed: 1,
      //   required,
      //   rfpId: selectedFile,
      //   perPage: rowsPerPage,
      //   page: page + 1,
      //   withDomains: true,
      // })
      // const closedSbirData = sbirClosedResponse.data.results.map((row) =>
      //   createSbirData(row)
      // )
      // setClosedSbirRows(closedSbirData)
      // setClosedSbirTotalCount(closedSbirData.length)
    } catch (e) {
      console.error(e)
      alert('Search failed. Please check your inputs and try again.')
    } finally {
      setLoadingSBIR(false)
    }
  }

  const handleSearchSbirSamWithDomain = async (page, rowsPerPage) => {
    const required = true

    setLoadingSBIRWithDomain(true)
    let newRfpId = null
    let newRfpSamId = null
    if (props.selectedFile === 'NEW') {
      newRfpId = await handleUpload()
      newRfpSamId = await handleUpload()
    }

    try {
      const response = await api.rfp.sam({
        rate,
        dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
        dateTo: moment().endOf('year').format('YYYY-MM-DD'),
        keywords,
        tenantId,
        rfpId: newRfpId || props.selectedFile,
        perPage: rowsPerPage,
        page: page + 1,
        agency,
        naicsCode,
        typeOfSetAside,
        required,
        withDomains: true,
      })
      // const sbirResponse = await api.rfp.sbir({
      //   dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
      //   dateTo: moment().endOf('year').format('YYYY-MM-DD'),
      //   keywords,
      //   rate,
      //   tenantId,
      //   closed: 0,
      //   perPage: rowsPerPage,
      //   page: page + 1,
      //   required,
      //   rfpId: newRfpId ? newRfpId : selectedFile,
      // })
      // const sbirClosedResponse = await api.rfp.sbir({
      //   dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
      //   dateTo: moment().endOf('year').format('YYYY-MM-DD'),
      //   keywords,
      //   rate,
      //   tenantId,
      //   closed: 1,
      //   perPage: rowsPerPage,
      //   page: page + 1,
      //   required,
      //   rfpId: newRfpId ? newRfpId : selectedFile,
      //   withDomains: true,
      // })
      setSamRows(response.data.results.map((row) => createSamData(row)))

      // setSbirRows(sbirResponse.data.results.map((row) => createSbirData(row)))

      // setClosedSbirRows(
      //   sbirClosedResponse.data.results.map((row) => createSbirData(row))
      // )
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingSBIRWithDomain(false)
    }
  }

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await api.rfp.getFilters({ tenantId })
        if (response.data && Array.isArray(response.data.type_of_set_aside)) {
          setFilters(response.data.type_of_set_aside)
        } else {
          console.error('Expected filters in response, got:', response.data)
          setFilters([])
        }
      } catch (e) {
        console.error('Error fetching filters:', e)
        setFilters([])
      }
    }

    fetchFilters()
  }, [])

  // Handle page change
  const handleSamPageChange = (event, newPage) => {
    setSamPage(newPage)
    handleSearchSbirSam(newPage, samRowsPerPage)
  }

  // Handle rows per page change
  const handleSamRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setSamRowsPerPage(newRowsPerPage)
    setSamPage(0)
    handleSearchSbirSam(0, newRowsPerPage)
  }
  const handleSbirPageChange = (event, newPage) => {
    setSbirPage(newPage)
  }

  const handleSbirRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setSbirRowsPerPage(newRowsPerPage)
    setSbirPage(0)
  }

  const handleClosedSbirPageChange = (event, newPage) => {
    setClosedSbirPage(newPage)
  }

  const handleClosedSbirRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setClosedSbirRowsPerPage(newRowsPerPage)
    setClosedSbirPage(0)
  }

  const handleSaveSearch = async () => {
    try {
      const response = await api.tenants.newsletters.post({
        rfpId: props.selectedFile,
        withDomains,
        agency,
        naicsCode,
        typeOfSetAside,
        tenantId,
        keywords,
        name: searchName,
        required: true,
      })

      if (response) {
        alert('Search saved to newsletter successfully!')
      }
    } catch (error) {
      console.error('Error saving search to newsletter:', error)
      alert('Failed to save search. Please try again.')
    }
  }

  // const handleFilter = (e) => {
  //   const value = e.target.value
  //   setSearchTerm(value)
  //   const filteredData = filterSolicitations()
  //   setRows(filteredData)
  // }

  const handleMouseDownFilter = (event) => {
    event.preventDefault()
  }

  const [searches, setSearches] = useState(() => {
    const savedSearches = localStorage.getItem('userSearchHistory')
    return savedSearches ? JSON.parse(savedSearches) : []
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'relative',
          mb: 2,
        }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 2,
          }}>
          RFP Search & Management
        </Typography>

        <Box sx={{ width: 'auto', height: '36px', visibility: 'hidden' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              gap: 1,
              flexGrow: 1,
              textWrap: 'nowrap',
              color: '#FFF',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleOpen}>
            <ManageSearch fontSize="medium" />
            <Typography
              sx={{
                fontSize: { xs: 12, sm: 16 },
              }}>
              Search for RFP
            </Typography>
          </Button>
          <TextField
            sx={{ flexGrow: 2 }}
            id="rfp-search"
            label="Filter results"
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              sx: { backgroundColor: 'background.paper' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle search"
                    // onClick={handleFilter}
                    onMouseDown={handleMouseDownFilter}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <CustomTableContainer>
          <Table>
            <EnhancedTable
              title="SAM solicitations"
              rows={samRows} 
              rowCells={rowSamCells}
              headCells={samheadCells}
              page={samPage}
              rowsPerPage={samRowsPerPage}
              onPageChange={handleSamPageChange} 
              onRowsPerPageChange={handleSamRowsPerPageChange} 
              isLastPage={isLastPage} 
              filterDisabled
              selectionDisabled
            />
          </Table>
        </CustomTableContainer>
        {/* <CustomTableContainer>
          <Table>
            <EnhancedTable
              title="Sbir solicitations"
              rows={
                sbirCurrentPageData && sbirCurrentPageData.length > 0
                  ? sbirCurrentPageData
                  : []
              }
              rowCells={rowCells}
              headCells={sbirheadCells}
              filterDisabled
              selectionDisabled
              onRowClick={handleRowClick}
              page={sbirPage}
              rowsPerPage={sbirRowsPerPage}
              totalCount={sbirRows.length}
              onPageChange={(event, newPage) => {
                setSbirPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10)
                setSbirRowsPerPage(newRowsPerPage)
                setSbirPage(0)
              }}
            />
          </Table>
        </CustomTableContainer> */}
        {/* <CustomTableContainer>
          <Table>
            <EnhancedTable
              title="Sbir past/closed solicitations"
              rows={
                closedSbirCurrentPageData &&
                closedSbirCurrentPageData.length > 0
                  ? closedSbirCurrentPageData
                  : []
              }
              rowCells={rowCells}
              headCells={sbirheadCells}
              filterDisabled
              selectionDisabled
              onRowClick={handlePastClosedRowClick}
              page={closedSbirPage}
              rowsPerPage={closedSbirRowsPerPage}
              totalCount={closedSbirRows.length}
              onPageChange={(event, newPage) => {
                setClosedSbirPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10)
                setClosedSbirRowsPerPage(newRowsPerPage)
                setClosedSbirPage(0)
              }}
            />
          </Table>
        </CustomTableContainer> */}
      </Box>

      <Modal keepMounted open={synopsisOpen} onClose={handleCloseSynopsis}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            width: { xs: 350, md: 500 },
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',

            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#555',
              },
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1',
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{selectedSolicitation.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {selectedSolicitation.description}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={open} onClose={null}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            borderRadius: 4,
            width: { xs: '90%', sm: 400, md: 500 },
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5">Search for RFP</Typography>

                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: { xs: 5, sm: 10 },
                    top: { xs: 5, sm: 10 },
                  }}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                }}>
                {!isUploadFormOpen && !isUploading && (
                  <TextField
                    required
                    label="Enter search name"
                    variant="outlined"
                    size="small"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    sx={{
                      flexGrow: 0,
                      width: { xs: '100%', sm: 'auto' },
                    }}
                  />
                )}
                {!isUploadFormOpen && !isUploading && (
                  <Button
                    variant="contained"
                    onClick={handleSaveSearch}
                    disabled={!searchName.trim()}
                    sx={{
                      whiteSpace: 'nowrap',
                    }}>
                    Save Search to Newsletter
                  </Button>
                )}
              </Grid>
            </Grid>
            {!isUploadFormOpen && !isUploading && (
              <>
                <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                  <CompanyDataSelector
                    companyData={companyData}
                    selectedFile={props.selectedFile}
                    setCompanyData={setCompanyData}
                    setSelectedFile={props.setSelectedFile}
                    handleSelectedFile={handleSelectedFile}
                    setIsUploading={setIsUploading}
                    setIsUploadFormOpen={setIsUploadFormOpen}
                    clear={() => {
                      setFileName('')
                      setFileDescription('')
                      setDomains([])
                    }}
                  />
                </Grid>
                {domains.length !== 0 && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="search"
                      label="Domains"
                      value={domains.map((dom) => `• ${dom.domain}`).join('\n')}
                      variant="outlined"
                      disabled={true}
                      multiline
                      rows={3}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="search"
                    label="Search keywords"
                    type="search"
                    variant="outlined"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
    <Typography variant="h6">Solicitation Type</Typography>
    <IconButton
      onClick={() =>
        setIsSolicitationTypeOpen(!isSolicitationTypeOpen)
      }>
      {isSolicitationTypeOpen ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </IconButton>
  </Box>
  <Collapse in={isSolicitationTypeOpen}>
    <FormControl fullWidth variant="outlined">
      <InputLabel id="solicitation-type-label">
        Select Solicitation Type
      </InputLabel>
      <Select
        labelId="solicitation-type-label"
        id="solicitation-type"
        multiple
        value={solicitationType}
        onChange={(e) => setSolicitationType(e.target.value)}
        label="Select Solicitation Type"
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() => handleDelete(value)}
                sx={{ borderRadius: '16px' }}
              />
            ))}
          </Box>
        )}>
        {/* Optional: Provide a "Select All" option */}
        <MenuItem value="">
          <em>Select Solicitation Type</em>
        </MenuItem>
        {solicitationTypesOptions.map((type) => (
          <MenuItem key={type} value={type}>
            <Checkbox checked={solicitationType.indexOf(type) > -1} />
            <ListItemText primary={type} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Collapse>
</Grid>


                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Agency</Typography>
                    <IconButton onClick={() => setIsAgencyOpen(!isAgencyOpen)}>
                      {isAgencyOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isAgencyOpen}>
                    <TextField
                      fullWidth
                      id="agency"
                      label="Agency"
                      variant="outlined"
                      value={agency}
                      onChange={(e) => setAgency(e.target.value)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Date</Typography>
                    <IconButton onClick={() => setIsDateOpen(!isDateOpen)}>
                      {isDateOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isDateOpen}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="date"
                          label="Start Date"
                          variant="outlined"
                          value={dateFrom}
                          onChange={(e) => setDateFrom(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type="date"
                          label="End Date"
                          variant="outlined"
                          value={dateTo}
                          onChange={(e) => setDateTo(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Naics Code</Typography>
                    <IconButton onClick={() => setIsNaicsOpen(!isNaicsOpen)}>
                      {isNaicsOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isNaicsOpen}>
                    <TextField
                      fullWidth
                      id="naics-code"
                      label="Naics Code"
                      variant="outlined"
                      value={naicsCode}
                      onChange={(e) => setNaicsCode(e.target.value)}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h6">Type of Set Aside</Typography>
                    <IconButton
                      onClick={() =>
                        setIsTypeOfSetAsideOpen(!isTypeOfSetAsideOpen)
                      }>
                      {isTypeOfSetAsideOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isTypeOfSetAsideOpen}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="type-of-set-aside-label">
                        Select Type Of Set Aside
                      </InputLabel>
                      <Select
                        labelId="type-of-set-aside-label"
                        id="type-of-set-aside"
                        value={typeOfSetAside}
                        label="Select Type Of Set Aside"
                        onChange={(e) => setTypeOfSetAside(e.target.value)}
                        displayEmpty>
                        <MenuItem value="">Select Type Of Set Aside</MenuItem>
                        {filters.map((filter, index) => (
                          <MenuItem key={index} value={filter}>
                            {filter}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Collapse>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Button
                      disabled={
                        props.selectedFile === '' ||
                        (props.selectedFile === 'NEW' &&
                          (fileName === '' || fileDescription === '')) ||
                        loadingSBIR
                      }
                      fullWidth
                      onClick={() => handleSearchSbirSam(0, samRowsPerPage)}
                      variant="contained"
                      sx={{ mr: 2 }}>
                      {loadingSBIR && (
                        <CircularProgress
                          size={20}
                          sx={{ color: 'grey', mr: 1 }}
                        />
                      )}
                      Search
                    </Button>
                    <Button
                      disabled={
                       props.selectedFile === '' ||
                        (props.selectedFile === 'NEW' &&
                          (fileName === '' || fileDescription === '')) ||
                        loadingSBIRWithDomain
                      }
                      fullWidth
                      onClick={() =>
                        handleSearchSbirSamWithDomain(0, sbirRowsPerPage)
                      }
                      variant="contained">
                      {loadingSBIRWithDomain && (
                        <CircularProgress
                          size={20}
                          sx={{ color: 'grey', mr: 1 }}
                        />
                      )}
                      Search with Domains
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
            {isUploadFormOpen && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Upload New Document</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="file-name"
                    label="Document Name"
                    variant="outlined"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} mb={4}>
                  <TextField
                    required
                    fullWidth
                    id="file-description"
                    label="Document Description"
                    variant="outlined"
                    value={fileDescription}
                    onChange={(e) => setFileDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleUpload}
                    disabled={
                      companyData.length === 0 ||
                      fileName.trim() === '' ||
                      fileDescription.trim() === '' ||
                      isUploading
                    }
                    startIcon={isUploading && <CircularProgress size={20} />}>
                    {isUploading ? 'Uploading...' : 'Upload Document'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      setIsUploading(false)
                      props.setSelectedFile('')
                      setCompanyData([])
                      setFileName('')
                      setFileDescription('')
                      setDomains([])
                    }}>
                    Cancel Upload
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>
      <Modal open={isPocModalOpen} onClose={handleClosePocModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
            width: { xs: 350, md: 500 },
          }}>
          <Typography variant="h6">Point of Contact</Typography>
          {pointOfContact && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                <strong>Full Name:</strong> {pointOfContact.fullName}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {pointOfContact.email}
              </Typography>
              <Typography variant="body1">
                <strong>Phone:</strong> {pointOfContact.phone}
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
      <Box sx={{ flexGrow: 1 }} />
      <CopyrightFooter />
    </Box>
  )
}
const mapStateToProps = (state) => ({
  samRows: state.rfp.samRows,
  sbirRows: state.rfp.sbirRows,
  closedSbirRows: state.rfp.closedSbirRows,
  selectedFile: state.rfp.selectedFile, 
})
const mapDispatchToProps = {
  setSamRows,
  setSbirRows,
  setClosedSbirRows,
  clearRfpData,
 setSelectedFile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RFPSearchAndManagementPage)
